import Sprite from "./portraits/sprite.png";

const people = [
  {
    name: "Jonas Bienz",
    role: "Trauzeuge",
    phone: "0764893085",
    email: "jonas.bienz@bluewin.ch",
    portrait: Sprite,
  },
  {
    name: "Jonas Bienz",
    role: "Trauzeuge",
    phone: "0764893085",
    email: "jonas.bienz@bluewin.ch",
    portrait: Sprite,
  },
  {
    name: "Jonas Bienz",
    role: "Trauzeuge",
    phone: "0764893085",
    email: "jonas.bienz@bluewin.ch",
    portrait: Sprite,
  },
  {
    name: "Jonas Bienz",
    role: "Trauzeuge",
    phone: "0764893085",
    email: "jonas.bienz@bluewin.ch",
    portrait: Sprite,
  },
  {
    name: "Jonas Bienz",
    role: "Trauzeuge",
    phone: "0764893085",
    email: "jonas.bienz@bluewin.ch",
    portrait: Sprite,
  },
  {
    name: "Jonas Bienz",
    role: "Trauzeuge",
    phone: "0764893085",
    email: "jonas.bienz@bluewin.ch",
    portrait: Sprite,
  },
];

export default people;
